@import url(../node_modules/bootstrap/dist/css/bootstrap.css);

* {
  box-sizing: border-box;
}

html {
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

.max-w-600 {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
